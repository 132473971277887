<template>
	<b-card
		:border-variant="color"
		:header-bg-variant="color"
		header-text-variant="white"
		align="center"
		:style="`margin-left: ${sideBarWidth + 5}px; z-index: 1000;`"
	>
		<template #header>
			<span>{{ nome }}</span>
			<button class="btn p-0" @click="$emit('close')" title="Fechar"><x-icon /></button>
		</template>
		<b-overlay :show="show" rounded="sm" :opacity="1">
			<b-card-text class="text-left">
				<div v-if="position">
					<a target="_blank" :href='`https://www.google.com/maps/place/${position.lat}+${position.lng}`'>
						<external-link-icon/>
					</a>
					<span>
						Posição: {{ position.lat }} {{ position.lng }}
						<copy-icon class="right-icon pointer" role="button" @click="copyPosition(position)" title="Copiar coordenadas"/><br/>
					</span>
				</div>

				<div v-if="estadoOee">
					<a target="_blank" :href='`${urlOEE}/#!/relatorioEficienciaEstacao/
						${filtroEficienciaSemanal.idMaquinas}/"
						${filtroEficienciaSemanal.dataInicial}"/"
						${filtroEficienciaSemanal.dataFinal}"/
						${filtroEficienciaSemanal.intervaloDiario}/"
						${filtroEficienciaSemanal.horaInicial}"/"
						${filtroEficienciaSemanal.horaFinal}"/
						${filtroEficienciaSemanal.turnos}/
						${filtroEficienciaSemanal.agrupamento}`'
					>
						<external-link-icon/>
					</a>
					<span>
						Eficiência Técnica Últimos 7 dias: {{ eficienciaTecnica.toFixed(2) }}%<br/>
					</span>

					<a target="_blank" :href='`${urlOEE}/#!/relatorioEficienciaEstacao/
						${filtroEficienciaDiaria.idMaquinas}/"
						${filtroEficienciaDiaria.dataInicial}"/"
						${filtroEficienciaDiaria.dataFinal}"/
						${filtroEficienciaDiaria.intervaloDiario}/"
						${filtroEficienciaDiaria.horaInicial}"/"
						${filtroEficienciaDiaria.horaFinal}"/
						${filtroEficienciaDiaria.turnos}/
						${filtroEficienciaDiaria.agrupamento}`'
					>
						<external-link-icon/>
					</a>
					<span>
						Eficiência Técnica Atual: {{ eficienciaTecnicaAtual.toFixed(2) }}%<br/>
					</span>

					<a target="_blank" :href="`${urlOEE}/#!/producao?m=${maquina.id}&a=${idArea}`">
						<external-link-icon/>
					</a>
					<span>
						Produção do Dia: {{ producaoDia }}<br/>
					</span>

					<a target="_blank" :href="`${urlOEE}/#!/motivosParadas?m=${maquina.id}&a=${idArea}`">
						<external-link-icon/>
					</a>
					<span>
						Tempo de Parada: {{ parada }}<br/>
					</span>

					<div>
						<a target="_blank" :href="`${urlOEE}/#!/estadoMaquina?m=${maquina.id}&a=${idArea}`">
							<external-link-icon/>
						</a>
						<span :title="dataDeEvento" class="w-100">
							Estado Atual: {{ estado.nome }} {{ estado.tipo?estado.tipo == "Predisposto"? '('+aliasPredisposto+')':'('+estado.tipo +')':'' }}
							<clock-icon class="right-icon" @mouseout="verificaData = false"  @mouseover="mouseOver(true)"/><br/>
						</span>
					</div>
				</div>

				<span :title="dataDeEvento" v-if="maquinaParada && estadoOee">
					<div>
						<a target="_blank" :href="`${urlOEE}/#!/motivosParadas?m=${maquina.id}&a=${idArea}`">
							<external-link-icon/>
						</a>
						Motivo Parada Atual: {{ motivoParada.nome }} {{ motivoParada.tipo ? '(' + motivoParada.tipo + ')' : '' }}
						<clock-icon  class="right-icon" @mouseout="verificaData = false" @mouseover="mouseOver(false)"/>
					</div>
				</span>

				<span v-if="!estadoOee">Módulo OEE Indisponível</span>
			</b-card-text>
		</b-overlay>
	</b-card>
</template>

<script>
	import { mapState } from "vuex";

	import comumService from "@/services/comum";
	import { OeeService } from "../../services/oee";
	import { TurnosService } from "../../services/turnos";

	import dayjs from "dayjs";

	const oeeService = new OeeService();
	const turnosService = new TurnosService();
	let duracao = 0;

	export default {
		props: {
			maquina: {
				type: Object,
				required: true
			}
		},

		computed: {
			...mapState(["sideBarWidth"])
		},

		data () {
			return {
				aliasPredisposto: localStorage.getItem("aliasPredisposto"),
				filtroEficienciaSemanal: {},
				filtroEficienciaDiaria: {},
				dataDeEvento: "",
				verificaData: false,
				show: true,
				color: "primary",
				parada: 0,
				position: null,
				estadoOee: true,
				eficienciaTecnica: 0,
				eficienciaTecnicaAtual: 0,
				producaoDia: 0,
				idArea: 0,
				urlOEE: "",
				estado: {
					nome: "Carregando",
					tipo_estado: "..."
				},
				maquinaParada: false,
				paradaProgramada: false,
				motivoParada: {
					nome: "Carregando",
					tipo_estado: "...",
					data_final:""
				},
				nome: "Carregando..."
			};
		},

		async mounted () {
			try {
				this.update();
				this.urlOEE = (await comumService.getModules()).oee.url;
			} catch (error) {
				console.error(error);
				this.$swal({
					title: "Falha ao buscar URL do módulo OEE!",
					type: "error",
					html: `<p>${(error.response && error.response.data.error) || error.message}</p>`,
					confirmButtonText: "Fechar",
					confirmButtonColor: "#6c757d"
				});
			}
			await this.updateOee();
		},

		methods: {
			calculaTempoParado (segundosDeParada) {
				let horas = Math.trunc(segundosDeParada / 3600);
				let minutos = Math.trunc((segundosDeParada - horas * 3600) / 60);
				let segundos = segundosDeParada - horas * 3600 - minutos * 60;
				horas = horas.toString();
				minutos = minutos.toString();
				segundos = segundos.toString();

				const duracaoDeparada = `${horas.padStart(2,"0")}:${minutos.padStart(2,"0")}:${segundos.padStart(2,"0")}`;
				return duracaoDeparada;
			},

			mouseOver (estadoMotivo) {
				this.verificaData = true;
				if (estadoMotivo)
					this.dataDeEvento = dayjs(this.estado.data_final).format("DD/MM/YYYY HH:mm:ss");
				else
					this.dataDeEvento = dayjs(this.motivoParada.data_final).format("DD/MM/YYYY HH:mm:ss");
			},

			async updateOee () {
				try {
					this.show = true;
					this.eficienciaTecnicaAtual = await this.eficiencia(false);
					this.eficienciaTecnica = await this.eficiencia(true);
					this.producaoDia = await this.producao();
					this.parada = this.calculaTempoParado(duracao);
					this.color = this.colorDefine(this.estado.tipo);
					this.estadoOee = true;
					this.show = false;
				} catch (error) {
					console.error(error);
					this.estadoOee = false;
					this.show = false;
				}
			},

			async update () {
				try {
					this.nome = this.maquina.nome;
					this.idArea = this.maquina.id_area;
					this.position = this.maquina.position;
					if (!this.maquina.summary) return;

					if (this.maquina.summary.ultimoEventoEstado) {
						this.estado.nome = this.maquina.summary.ultimoEventoEstado.estado.nome;
						this.estado.tipo = this.maquina.summary.ultimoEventoEstado.estado.tipo_estado;
					}

					if (this.maquina.summary.ultimoEventoMotivoParada) {
						this.maquinaParada = true;
						this.paradaProgramada = this.maquina.summary.ultimoEventoMotivoParada.motivoParada.programada;
						this.motivoParada.nome = this.maquina.summary.ultimoEventoMotivoParada.motivoParada.nome;
						this.motivoParada.tipo = this.maquina.summary.ultimoEventoMotivoParada.motivoParada.programada ? "Programada" : "Não Programada";
						this.motivoParada.data_final = this.maquina.summary.ultimoEventoMotivoParada.data_final;
					} else {
						this.maquinaParada = false;
					}
				} catch (error) {
					console.error(error);
					this.$swal({
						title: "Falha ao buscar dados da máquina!",
						type: "error",
						html: `<p>${(error.response && error.response.data.error) || error.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			async producao () {
				let producaoTotal = 0;
				const producaoPorTurno = await oeeService.getProducao({
					maquina: [this.maquina.id],
					inicio: dayjs().format("YYYY/MM/DD 00:00:00"),
					fim: dayjs().format("YYYY/MM/DD HH:mm:ss")
				});

				for (const valor of producaoPorTurno.data[0].real) {
					if (valor.valores.length) {
						producaoTotal = valor.valores.reduce((total, numero) => total + Number(numero.ok), 0);
					} else {
						producaoTotal += Number(valor.valores);
					}
				}
				return producaoTotal;
			},

			/**
			 * @param {boolean} diariaSemanalFlag true = semanal | false = diária
			 */
			async eficiencia (diariaSemanalFlag) {
				const turnos = (await turnosService.listTurnos()).map(item => item.numero);
				this.filtroEficienciaDiaria = {
					idMaquinas: this.maquina.id,
					dataInicial: dayjs().format("YYYY-MM-DD"),
					dataFinal: dayjs().format("YYYY-MM-DD"),
					intervaloDiario: false,
					horaInicial: "00:00:00",
					horaFinal: "23:59:59",
					turnos: turnos.toString(),
					agrupamento: "Dia"
				};

				this.filtroEficienciaSemanal = {
					idMaquinas: this.maquina.id,
					dataInicial: dayjs().subtract(6,"days").format("YYYY-MM-DD"),
					dataFinal: dayjs().format("YYYY-MM-DD"),
					intervaloDiario: false,
					horaInicial: "00:00:00",
					horaFinal: "23:59:59",
					turnos: turnos.toString(),
					agrupamento: "Dia"
				};
				const eficienciaSemana = await oeeService.getEficiencia(diariaSemanalFlag ? this.filtroEficienciaSemanal : this.filtroEficienciaDiaria);

				let eficienciaTecnicaCalculada = 0;
				for (const key in eficienciaSemana) {
					if (Object.hasOwnProperty.call(eficienciaSemana, key)) {
						const eficiencia = eficienciaSemana[key];
						for (const dia of eficiencia)
							eficienciaTecnicaCalculada += Number(dia.et.relativo);
						const i = eficiencia.length;
						duracao = eficiencia[i - 1].microParada.abs + eficiencia[i - 1].parada.abs;
					}
				}
				return diariaSemanalFlag ? (eficienciaTecnicaCalculada / 7) : eficienciaTecnicaCalculada;
			},

			colorDefine (estado) {
				if (!estado || estado == "Rodando" || estado == "Predisposto")
					return "primary";

				if (estado == "Parada Programada")
					return "warning";

				return "danger";
			},

			async copyPosition (position) {
				await navigator.clipboard.writeText(`${position.lat} ${position.lng}`);
				this.$snotify.success("Coordenadas copiadas.", "Sucesso!");
			}
		},

		watch: {
			maquina: ["update","updateOee"]
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: fit-content;
		position: absolute;
		bottom: 5px;
	}

	.card-header {
		span {
			line-height: 27px;
		}

		button {
			float: right;

			svg {
				stroke: white;
			}
		}
	}

	.card-body span {
		height: 35px;
		line-height: 35px;
		vertical-align: middle;
	}

	.right-icon {
		vertical-align: middle;
		color:#007bff;
		margin-left: 0.5rem;
	}
</style>
