var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"responsive-map"}},[_c('alarms-list',{style:(("width: " + _vm.alarmsPanelWidth + "px;")),attrs:{"idMaquina":_vm.selectedMarker && _vm.selectedMarker.maquina.id}}),_c('gmap-map',{ref:"mapRef",staticClass:"map",style:(("width: calc(100% - " + _vm.sideBarWidth + "px - " + _vm.alarmsPanelWidth + "px); margin-left: " + _vm.sideBarWidth + "px;")),attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"hybrid"}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"clickable":true,"icon":{
				url: _vm.getIcon(m.maquina.id),
				scaledSize: {
					width: Number((m.maquina.summary && m.maquina.summary.width) || 27),
					height: Number((m.maquina.summary && m.maquina.summary.height) || 43)
				},
				labelOrigin: {
					x: Number((m.maquina.summary && m.maquina.summary.labelX) || 15),
					y: -10
				},
			},"label":{ text: _vm.exibirNome ? m.maquina.nome : ' ', color: m.maquina.cor, fontWeight: 'bold', className: m.maquina.bgClass }},on:{"dblclick":function($event){return _vm.zoomIn(m)},"click":function($event){_vm.selectedMarker = m}}})}),1),(_vm.selectedMarker)?_c('machine-summary',{attrs:{"maquina":_vm.maquinas.find(function (m) { return m.id == _vm.selectedMarker.maquina.id; })},on:{"close":function($event){_vm.selectedMarker = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }